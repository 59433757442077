var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('section', {
    staticClass: "container",
    attrs: {
      "id": "Entity"
    }
  }, [_c('div', {
    staticClass: "wz-flexer _jcc _fww"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content ovauto"
  }, [_c('pre', [_vm._v(_vm._s(_vm.aktonly))]), _c('pre', [_vm._v(_vm._s(_vm.aktart))]), _c('ul', _vm._l(_vm.item, function (it, i) {
    return _c('li', {
      staticClass: "_33"
    }, [_c('div', {
      staticClass: "wz-input"
    }, [_c('label', [_vm._v(_vm._s(i))]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.item[i],
        expression: "item[i]"
      }],
      domProps: {
        "value": _vm.item[i]
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.item, i, $event.target.value);
        }
      }
    })])]);
  }), 0)])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Entity")])]);
}];
export { render, staticRenderFns };